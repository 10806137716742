<template>
  <li class="criteria-list search-suggest__item" :class="{ focus: hasFocus }">
    <button
      type="button"
      class="search-history__button text-ellipsis"
      :class="`js-criteria-focus${index}`"
      @click="goToTopicsSearchResults"
    >
      <i
        aria-hidden="true"
        class="glyphs glyphs-search search-suggest__icon"
      ></i>
      {{ criterionLabel }}
    </button>
    <button
      class="search-suggest__item-sub mobile-only"
      type="button"
      @click="setCurrentKeywordAndUpdateSuggestions"
    >
      <i class="glyphs glyphs-arrow_upper_left" aria-hidden="true"></i>
    </button>
  </li>
</template>

<script>
import { mapActions } from 'vuex';
import { FOCUS_SEARCH_INPUT } from '../../store/mutation-types';
import utils from '../../../common/routings/utils';
import { captureEvent } from '../../../common/utils';
import topics from '../../../common/routings/topics';

export default {
  props: {
    criterion: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    hasFocus: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    criterionLabel() {
      return this.criterion.keyword;
    }
  },

  methods: {
    ...mapActions(['setCurrentKeyword']),

    goToTopicsSearchResults() {
      captureEvent({
        category: 'サイト内検索',
        action: 'SearchPartsClick',
        label: 'saved-search',
        value: this.index + 1
      });
      this.setCurrentKeyword(this.criterion.keyword);
      utils.goToURL(topics.searchPath(this.criterion.keyword));
    },

    setCurrentKeywordAndUpdateSuggestions() {
      this.$store.dispatch(
        'updateKeywordAndSuggestions',
        `${this.criterion.keyword} `
      );
      this.$store.commit(FOCUS_SEARCH_INPUT);
    }
  }
};
</script>
