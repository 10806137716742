<template>
  <div
    class="search-suggest"
    v-bind:class="{ 'search-suggest--second': isSecond }"
  >
    <search-candidates v-if="search_candidate_active" />
    <keyword-suggestions v-if="search_suggestion_active" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchCandidates from './SearchCandidates.vue';
import KeywordSuggestions from './KeywordSuggestions.vue';

export default {
  computed: {
    ...mapGetters(['search_candidate_active', 'search_suggestion_active'])
  },
  components: { SearchCandidates, KeywordSuggestions },
  props: {
    isSecond: {
      type: Boolean,
      default: false
    }
  }
};
</script>
