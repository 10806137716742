<template>
  <form
    class="flex-space-between"
    role="ignore-empty-submit"
    action="/search/topics"
    accept-charset="UTF-8"
    method="get"
    autocomplete="off"
    @submit.prevent="goToSearchResults"
  >
    <span class="close" @click="hideSearchScreen"
      ><i class="glyphs glyphs-arrow_left" aria-hidden="true"></i
    ></span>

    <input
      name="q"
      class="header-sp__search-input"
      id="sp_search_input"
      @input="updateCurrentKeyword"
      @keydown.up.prevent="handleUp"
      @keydown.down.prevent="handleDown"
      :value="current_keyword"
      type="search"
      placeholder="病名、症状、体の部位名、お薬名から探す"
      role="ignore-empty-tar get"
    />
    <button
      type="button"
      class="delete"
      @click="resetCurrentKeyword"
      v-if="current_keyword.length > 0"
    >
      <i class="glyphs glyphs-cross" aria-hidden="true"></i>
    </button>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FOCUS_SEARCH_INPUT } from '../../store/mutation-types';
import utils, { UNFOCUSED } from '../../utils';

export default {
  mounted() {
    const vm = this;
    vm.$nextTick(() => vm.focusSearchInput());
    vm.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case FOCUS_SEARCH_INPUT:
          vm.focusSearchInput();
          break;
        default:
          break;
      }
    });
  },

  watch: {
    focus_candidate_index: function(newIndex, _oldIndex) {
      if (this.search_app_active && newIndex === UNFOCUSED) {
        this.focusSearchInput();
      }
    }
  },

  computed: {
    ...mapGetters([
      'current_keyword',
      'focus_candidate_index',
      'search_app_active'
    ])
  },

  methods: {
    ...mapActions([
      'updateKeywordAndSuggestions',
      'setFirstFocusCandidateIndex',
      'setLastFocusCandidateIndex',
      'hideSearchScreen'
    ]),

    focusSearchInput() {
      this.$el.querySelector('#sp_search_input').focus();
    },

    updateCurrentKeyword(e) {
      this.updateKeywordAndSuggestions(e.target.value);
    },

    resetCurrentKeyword(e) {
      this.updateKeywordAndSuggestions('');
      this.$store.commit(FOCUS_SEARCH_INPUT);
    },

    goToSearchResults(e) {
      utils.goToSearchURL('/search/topics', {
        q: utils.replaceSlash(this.current_keyword.trim())
      });
    },

    handleUp() {
      this.setLastFocusCandidateIndex();
    },

    handleDown() {
      this.setFirstFocusCandidateIndex();
    }
  }
};
</script>
